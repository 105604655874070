/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
  ::-webkit-scrollbar-track {
  background: transparent; 
}
  ::-webkit-scrollbar-thumb {
  background: #888; 
    border-radius:8px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888; 
    border-radius:8px;
} 
 */
 

 @keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-5px);
  }
}
body{
  background: rgb(255, 254, 249);
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

 .bouncing-loader {
  display: flex;
  width: 50px;
  position: relative;
}

.bouncing-loader > div {
  width: 6px;
  height: 6px;
  margin: 1px 2px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.8s infinite alternate;
}


main {
  height: calc(100vh - 150px);
  display: flex;
  padding: 15px;
  flex-direction: column;

}


form {
  height: 57px;
  position: fixed;
  bottom: 15px;
  width: 100%;
  max-width: 680px;
  display: flex;
  font-size: 1.5rem;
}


button:disabled {
  opacity: 0.5;
  /* cursor: not-allowed; */
}


.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

ul, li {
  text-align: left;
  list-style: none;
}


.message {
  display: flex;
  align-items: flex-start;
}


.sent {
  flex-direction: row-reverse;
}

.sent p {
  align-self: flex-end;
  border: 1px solid white;
  border-top-right-radius: 3px;
}
.received p {
  background: white;
  color: black;
  border-top-left-radius: 3px;

}

.profile-pic {
  margin: 2px 5px;
  font-size: 40px;
}

